import React from "react";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import * as request from "superagent";
import MetaTags from 'react-meta-tags';
import cheerio from "cheerio";
import {withRouter} from "../util/withRouter";

class Page extends React.Component {
    constructor() {
        super();
        this.state = ({url: null, page: null, wpPage: null});
    }

    setupPage(props) {
        let relativeUrl = props.router.location.pathname.substring("/page".length);
        this.setState({url: relativeUrl});


        let page = props.pageLookup[relativeUrl];

        this.setState({page});
        if (page) {
            this.fetchContent(page.key, (wpPage) => {
                this.setState({wpPage});
            })
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setupPage(nextProps);
    }


    componentWillMount() {
        this.setupPage(this.props);
    }

    fetchContent(pageId, callback) {
        request.get("//" + window.location.host + "/wp/?json=get_page&id=" + pageId)
            .end((err, res) => {
                callback(res.body.page)
            })
    }

    render() {
        if (!this.state.wpPage) {
            return null;
        }
        let submenu;
        if (this.state.page && this.state.page.children) {
            submenu = (
                <ul className="mobileShortcutNav">
                    {this.state.page.children.map(child => {
                        return <li key={child.key}><Link to={child.value}>{child.text}</Link></li>
                    })}
                </ul>
            );
        }
        let page = this.state.wpPage;

        let excerpt = cheerio.load(page.excerpt).text();
        let maxLength = 200;
        if (excerpt.length > maxLength) {
            excerpt = excerpt.substring(0, excerpt.indexOf(" ", maxLength)) + "...";
        }

        let meta = (
            <MetaTags>
                <title>{page.title_plain}</title>
                <meta property="og:url"
                      content={"https://www.skurusim.se/page/" + page.slug}/>
                <meta property="og:type" content="website"/>
                <meta property="og:title" content={page.title_plain}/>
                <meta property="og:description"
                      content={excerpt}/>
                <meta property="og:image"
                      content="https://www.skurusim.se/images/sharing_image.png"/>
            </MetaTags>
        );

        return (
            <div className="pageContent">
                {meta}
                <div
                    dangerouslySetInnerHTML={{__html: this.state.wpPage.content.replace("http://https://sportadmin.se/form", "/forms")}}/>
                {submenu}
            </div>
        );
    }
}

Page.propTypes = {
    pageLookup: PropTypes.object.isRequired,
};

export default withRouter(Page);