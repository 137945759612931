import React from "react";
import PropTypes from "prop-types";
import SubMenuArrow from "./SubMenuArrow";

export default class MultiLevelDropdown extends React.Component {
    constructor() {
        super();
        this.state = ({open: false, openItems: {}, value: null});
    }

    componentWillMount() {
        this.updateOptionsValue(this.props);
    }

    componentWillReceiveProps(nextProps) {
        this.updateOptionsValue(nextProps);
    }

    updateOptionsValue(props) {
        let openItems = {};
        let value = props.value;
        if (!value) {
            if (this.props.router.location.pathname.startsWith("/page")) {
                value = props.pageLookup[this.props.router.location.pathname.substring("/page".length)];
            } else if (this.props.router.location.pathname.startsWith("/records")) {
                value = props.pageLookup["/records"];
            } else {
                value = props.pageLookup[this.props.router.location.pathname];
            }

        }
        if (value) {
            let i = value;
            while (i) {
                openItems[i.key] = true;
                i = props.pageLookup[i.parent];
            }
        }
        this.setState({value: value, openItems});
    }

    toggleSubMenu(id, e) {
        let openItems = Object.assign(this.state.openItems);
        openItems[id] = openItems[id] !== true;
        this.setState({openItems});
        e.preventDefault();
        e.stopPropagation();
    }


    itemClick(item) {
        this.props.onItemClick(item);
        if (this.props.closeOnValueChange === true) {
            this.setState({open: false});
        }
    }

    render() {
        return (
            <div className={"multilevel-dropdown"}>
                <div className={"menu " + (this.props.open ? "open" : "closed")}>
                    {this.getMenuItems(this.props.menu)}
                </div>
            </div>
        );
    }

    getMenuItems(menuItems, depth) {
        if (!depth) {
            depth = 0;
        }
        let self = this;
        return menuItems.map((option, i) => {
            let isOpen = self.state.openItems[option.key] === true;
            let isActive = this.state.value && this.state.value.key === option.key;
            if (option.children && option.children.length > 0) {
                let style = {
                    maxHeight: isOpen ? "600px" : "0px",
                    transition: "all 200ms ease-in-out",
                    overflow: "hidden",
                };

                return (
                    <div className="menuItems" key={option.key}>
                        <div className={"menuItem " + "level" + depth + " " + (isActive ? "active" : "")}
                             onClick={self.itemClick.bind(this, option)}
                             key={option.key}>
                            <span>{option.text}</span>
                            <SubMenuArrow onClick={self.toggleSubMenu.bind(this, option.key)} open={isOpen}/>
                        </div>
                        <div className="subMenu" style={style}>
                            {this.getMenuItems(option.children, depth + 1)}
                        </div>
                    </div>
                );
            } else {
                return <div className="menuItems" key={option.key}>
                    <div className={"menuItem " + "level" + depth + " " + (isActive ? "active" : "")} key={option.key}>
                        {/*<Link to={option.value}>{option.text}</Link>*/}
                        <span onClick={self.itemClick.bind(this, option)}
                              style={{flexGrow: 1}}>
                            {option.text}</span>
                    </div>

                </div>;
            }
        })
    }


}

MultiLevelDropdown.propTypes = {
    options: PropTypes.array.isRequired,
    onItemClick: PropTypes.func.isRequired,
    value: PropTypes.object,
    open: PropTypes.bool.isRequired,
    closeOnValueChange: PropTypes.bool,
};
