export const isAdmin = () => {
    return window.account && window.account.roles.admin === true;
};

export const isLoggedIn = () => {
    return window.account && window.account.fullname;
};

export const isParent = () => {
    return Object.values(window.account.manages).filter(manage => manage.type === "parent").length > 0;
};

export const isCoach = () => {
    return (window.account && window.account.managesGroups);
};

export const isActiveCoach = (competition) => {
    if (!window.account || !window.account.managesGroups || !competition) {
        return false;
    }
    let managesGroups = window.account.managesGroups.map(mg => mg.id);
    let competitionGroups = competition.groups.map(g => g.id);
    return managesGroups.filter(g => competitionGroups.includes(g)).length > 0;
};

export const canSignup = () => {

};

