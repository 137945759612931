import React from "react";
import {Link} from "react-router-dom";
import {ageRecordAges, ageRecords, clubRecords, finaRecords} from "../../api/records";
import {Button, Dropdown} from "semantic-ui-react";
import PropTypes from "prop-types";
import "./records.scss"
import {displayEvent, displayTime} from "../../util/utilfuncs";
import {withRouter} from "../../util/withRouter";

class ClubRecords extends React.Component {
    constructor() {
        super();
        this.state = ({records: {}, ages: {}, age: null, finaRecords: null, filters: []});

    }

    componentWillMount() {
        let ageRange = this.props.router.params.age;
        if (ageRange) {
            this.ageRecords(ageRange);
        } else {
            this.loadClubRecords();
        }
        ageRecordAges(ages => {
            this.setState({ages: Object.values(ages)});
        });
    }

    componentWillReceiveProps(nextProps) {
        let ageRange = nextProps.router.params.age;
        if (ageRange) {
            this.ageRecords(ageRange);
        } else {
            this.loadClubRecords();
        }
    }

    loadClubRecords(filters) {
        clubRecords(filters, records => {
            this.setState({records, finaRecords: null, ageRange: null, filters: filters || []});
        });
    }

    loadFinaRecords() {
        finaRecords(finaRecords => {
            this.setState({finaRecords});
        });
    }

    ageRecords(ageRange) {
        if (ageRange) {
            let max, min;
            if (!isNaN(ageRange)) {
                min = ageRange;
                max = ageRange;
            } else if (ageRange.indexOf("-") > 0) {
                let ar = ageRange.split(/-/);
                min = ar[0];
                max = ar[1];
            }
            ageRecords(min, max, r => {
                this.setState({
                    records: r[Object.keys(r)[0]],
                    finaRecords: null,
                    ageRange: ageRange
                });
            });
        }
    }

    navigateToAge(e, select) {
        let value = this.state.ages[Object.keys(this.state.ages)[select.value]];
        let range;
        if (value.min === value.max) {
            range = value.min;
        } else {
            range = value.min + "-" + value.max;
        }
        this.props.router.navigate("/records/" + range);
    }

    navigateToClubRecords() {
        this.props.router.navigate("/records");
    }

    render() {
        let recordType = "Klubbrekord";
        if (this.state.ageRange) {
            recordType = "Åldersrekord för åldern " + this.state.ageRange + " år";
        }

        let ages = Object.values(this.state.ages).map((a, i) => {
            let value = a.max === a.min ? a.max : a.min + " - " + a.max;
            return {
                key: value,
                text: (a.max === a.min ? a.max : a.min + " - " + a.max) + " år",
                value: i
            }
        });

        let viewComponent;
        if (this.state.finaRecords) {
            viewComponent = <FinaView records={this.state.finaRecords}/>;
        } else {
            viewComponent = <RecordsView records={this.state.records}
                                         age={this.state.ageRange}/>;
        }

        return (
            <div>
                <h1>{recordType}</h1>
                <div className="records">
                    <Button onClick={this.navigateToClubRecords.bind(this)} size="medium"
                            color="green">Klubbrekord</Button>
                    <Button onClick={this.loadFinaRecords.bind(this)} size="medium" color="green">FINA-poäng</Button>
                    {/*<Button*/}
                    {/*onClick={this.loadClubRecords.bind(this, [{range: {date: {gte: new Moment().subtract(2, "year").format("YYYY-MM-DD")}}}])}*/}
                    {/*size="medium" color="green">Bäst senaste året</Button>*/}
                    <Dropdown onChange={this.navigateToAge.bind(this)} placeholder={"Välj åldersrekord"}
                              floating labeled button className="icon green"
                              scrolling

                              icon="caret down"
                              options={ages}/>
                    {viewComponent}
                </div>
            </div>
        );
    }


}

export default withRouter(ClubRecords);

class RecordsView extends React.Component {
    render() {
        let trIndex = 0;
        let records = this.props.records;
        let recordsView = Object.keys(records).map(gender => {
            let genderRecords = records[gender];
            let grs = Object.keys(genderRecords).map(pool => {
                let poolRecords = genderRecords[pool];
                let prs = Object.keys(poolRecords).map(stroke => {
                    let strokeRecords = poolRecords[stroke];
                    let srs = Object.keys(strokeRecords).map(distance => {
                        let record = strokeRecords[distance];
                        let age = this.props.age || null;
                        return (
                            <tr key={record.id}>
                                <td className="eventname link">
                                    <Link
                                        to={"/records/event/" + record.eventId + "/" + pool + "/" + gender + "/" + age}>
                                        {displayEvent(record.eventName)}
                                    </Link>
                                </td>
                                <td className="name">
                                    <div><a
                                        href={"http://tempusopen.se/index.php?r=swimmer/view&id=" + record.swimmerId}>{record.firstname + " " + record.lastname}</a>
                                    </div>
                                </td>
                                <td className="date">{record.date}</td>
                                <td className="competition">{record.competition}</td>
                                <td className="time">{displayTime(record.time)}</td>
                            </tr>
                        );
                    });
                    return [
                        <tr key={++trIndex}>
                            <td className="recordheader stroke" colSpan={6}>{stroke}</td>
                        </tr>, srs
                    ]
                });
                return [
                    <tr key={++trIndex}>
                        <td className="recordheader pool" colSpan={6}>{pool}m-bassäng</td>
                    </tr>, ...prs
                ]
            });
            return [<tr key={++trIndex}>
                <td className="recordheader gender" colSpan={6}>{window.genderLookup(gender)}</td>
            </tr>, ...grs];
        });


        return (
            <table className="recordtable">
                <tbody>
                {recordsView}
                </tbody>
            </table>
        )
    }
}

RecordsView.propTypes = {
    records: PropTypes.object,
    age: PropTypes.string
};

class FinaView extends React.Component {
    render() {
        let records = this.props.records;


        let recordsView = Object.keys(records).map(yob => {
            return [
                <tr key={yob}>
                    <td className="recordheader stroke" colSpan={4}>{yob}</td>
                </tr>,
                records[yob].map((r, i) => {
                    return (
                        <tr key={i}>
                            <td className="rank">{i + 1}</td>
                            <td className="total">{r.totalFina}</td>
                            <td className="events">{r.totalEvents}</td>
                            <td className="name">{r.info.firstname + " " + r.info.lastname}</td>
                        </tr>
                    )
                })
            ]

        });

        return (
            <div style={{paddingTop: "20px"}}>
                <h3>FINA-poäng senaste året</h3>
                <p>Visar totala antalet FINA-poäng under se senaste 12 månaderna för simmare under 18 år. Läs mer om <a
                    href="http://www.svensksimidrott.se/Varagrenar/Simning/Simstatistik/FINApoang">FINA-poäng</a></p>
                <table className="recordtable fina">
                    <thead>
                    <tr>
                        <th className="rank">#</th>
                        <th className="total">FINA-poäng</th>
                        <th className="events">Antal heat</th>
                        <th className="name">Namn</th>
                    </tr>
                    </thead>
                    <tbody>
                    {recordsView}
                    </tbody>
                </table>
            </div>
        )
    }
}

FinaView.propTypes = {
    records: PropTypes.object,
};
