import './App.css';
import {
    BrowserRouter as Router,
    Routes,
    Route,

} from "react-router-dom";
import {useState} from "react";
import request from "superagent";
import {isActiveCoach, isAdmin} from "./util/auth";
import Layout from "./components/Layout";
import Startpage from "./routes/Startpage";
import Page from "./routes/Page";
import St from "react-datepicker";
import ClubRecords from "./routes/records/ClubRecords";
import EventRecords from "./routes/records/EventRecords";
import ViewPost from "./routes/viewposts/ViewPost";



function App(props) {
    const wpRootUrl = window.location.protocol + "//" + window.location.host + "/wp";
    const [menu, setMenu] = useState([]);
    const [pageLookup, setPageLookup] = useState({});
    const [loginState, setLoginState] = useState(false);
    const [originalMenuItems, setOriginalMenuItems] = useState([]);
    const [menuLoading, setMenuLoading] = useState(false);

    if (!menuLoading) {
        setMenuLoading(true);
        request.get(wpRootUrl + "/wp-json/wp-api-menus/v2/menus/5")
            .end((err, res) => {
                let pageLookup = {};
                let menu = buildMenu(res.body.items, pageLookup);
                setMenu(menu);
                setPageLookup(pageLookup);
                setOriginalMenuItems(res.body.items);
            });
    }

    const loginStateChange = (loginState) => {
        let menu = buildMenu(originalMenuItems, pageLookup);
        setMenu(menu);
        setLoginState(loginState);
    }

    // let page = function () {
    //     return
    // };

    return (
        <Router>
            <Layout menu={menu} pageLookup={pageLookup}
                    loginStateChange={(m) => loginStateChange(m)}>
                <Routes>
                    <Route path="/" element={<Startpage/>}/>
                    <Route path="/records" exact={true} element={<ClubRecords/>}/>
                    <Route path="/records/:age" exact={true} element={<ClubRecords/>}/>
                    <Route path="/records/event/:eventId/:pool/:gender/:age" exact={true} element={<EventRecords/>}/>
                    {/*<Route path="/page/rekord.html" component={() => window.location = "/records"}/>*/}
                    {/*<Route path="/page/kalender.html" exact component={() => window.location = "/competitions"}/>*/}
                    <Route path="page/*" exact={false} element={<Page pageLookup={pageLookup}/>}/>
                    <Route path="post/*" element={<ViewPost/>}/>
                    {/*<Route path="/admin/groups" component={GroupMapping}/>*/}
                    {/*<Route path="/admin/accounts" component={Accounts}/>*/}
                    {/*<Route path="/admin/members" component={Members}/>*/}
                    {/*<Route path="/admin/competitions" component={CompetitionAdmin}/>*/}
                    {/*<Route path="/admin/synchronize" component={Synchronize}/>*/}
                    {/*<Route path="/admin/skuruplasket" component={UtmanarenAdmin}/>*/}
                    {/*<Route path="/admin" component={Admin}/>*/}
                    {/*<Route path="/competitions" component={Competitions}/>*/}
                    {/*<Route path="/competition/:competitionSlug" component={Competitions}/>*/}
                    {/*<Route path="/cafe" component={restricted(Bookings)}/>*/}
                    {/*<Route path="/forms" component={SportAdminForm}/>*/}
                    {/*<Route path="/registration/:token" component={Registration}/>*/}
                    {/*<Route path="/resetpassword/:token" component={Registration}/>*/}
                    {/*<Route path="/skuruplasket" component={Utmanaren}/>*/}
                </Routes>
            </Layout>
        </Router>
    );


}

const buildMenu = (wpMenuItems, pageLookup, parent) => {
    let menuItems = wpMenuItems.map(child => {

        let subPath = child.url.replace(/https{0,1}:\/\/.*?\/wp/, "");
        let relativePath;
        if (child.url.match(/https{0,1}:\/\/.*?\/wp.*/)) {
            relativePath = "/page" + subPath;
        } else {
            relativePath = child.url;
        }
        let children;
        if (child.children) {
            children = buildMenu(child.children, pageLookup, child.object_id);
        }
        let page = {key: child.object_id, text: child.title, value: relativePath, children, parent};
        pageLookup[child.object_id] = page;
        if (subPath) {
            pageLookup[subPath] = page;
        }
        return page;
    });

    if ((isAdmin() || isActiveCoach()) && !parent) {
        let admin = {
            key: "admin", text: "Admin", value: "/admin", children: []
        };
        pageLookup["/admin"] = admin;


        let wordpress = {
            key: "wordpress",
            "text": "Wordpress",
            value: "/wp/wp-admin/index.php",
            parent: "/admin",
            external: true
        };
        admin.children.push(wordpress);
        pageLookup[wordpress.value] = wordpress;

        // let groups = {key: "groups", text: "Grupper", value: "/admin/groups", parent: "/admin"};
        // admin.children.push(groups);
        // pageLookup[groups.value] = groups;

        if (window.account.roles.admin === true) {
            // let accounts = {key: "accounts", text: "Konton", value: "/admin/accounts", parent: "/admin"};
            // admin.children.push(accounts);
            // pageLookup[accounts.value] = accounts;

            let synchronize = {
                key: "synchronize",
                text: "Synkronisera",
                value: "/admin/synchronize",
                parent: "/admin"
            };
            admin.children.push(synchronize);
            pageLookup[synchronize.value] = synchronize;


            let bookingAdmin = {
                key: "bookingadmin",
                text: "Bokningar",
                value: "/admin/bookings",
                parent: "/admin"
            };

            pageLookup[bookingAdmin.value] = bookingAdmin;
        }


        menuItems.push(admin);
    }
    return menuItems;
}
export default App;
