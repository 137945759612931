import React from "react";
import {Link, withRouter} from "react-router-dom";
import PropTypes from "prop-types";
import request from "superagent";
import cheerio from "cheerio";
import "./postlisting.scss";
import {Dimmer, Icon, Item, Loader} from "semantic-ui-react";
import Moment from "moment";

class PostListing extends React.Component {
    constructor() {
        super();
        this.state = ({posts: null, page: 1, pageCount: null});
        this.wpRootUrl = window.location.protocol + "//" + window.location.host + "/wp";
    }

    componentWillMount() {
        this.loadPosts(this.state.page);
    }

    loadPosts(page) {
        request.get("/wp/category/" + this.props.postType + "/?json=1&count=8&orderby=date&order=desc&page=" + page)
            .end((err, res) => {
                this.setState({posts: res.body.posts, pageCount: res.body.pages, page});
            });
    }

    gotoPage(pageNumber) {
        this.loadPosts(pageNumber)
    }

    newer() {
        if (this.state.page > 1) {
            this.loadPosts(this.state.page - 1);
        }
    }

    older() {
        if (this.state.page < this.state.pageCount) {
            this.loadPosts(this.state.page + 1);
        }
    }

    render() {
        let footer;
        let pagingPageNumberVisible = 5;
        if (this.state.pageCount > 1) {

            let pagingPages = [];
            let minPage = Math.max(this.state.page - 2, 1);
            let maxPage = Math.min(minPage + 4, this.state.pageCount);

            if (maxPage - minPage < 4 && minPage > 1) {
                minPage = Math.max(1, maxPage - 4);

            }

            // if (this.state.page > 1) {
            let newerClasses = "item pagingNewer ";
            if (this.state.page <= 1) {
                newerClasses += "disabled";
            }
            pagingPages.push(<div key="newer" onClick={this.newer.bind(this)} className={newerClasses}><Icon
                name="angle double left"/>
            </div>)
            // }
            for (let i = minPage; i <= maxPage; i++) {
                pagingPages.push(<div key={i} onClick={this.gotoPage.bind(this, i)}
                                      className={"item pagingPage" + (this.state.page === i ? " active" : "")}>{i}</div>)
            }
            let olderClasses = "item pagingOlder ";
            if (this.state.page >= this.state.pageCount) {
                olderClasses += " disabled";
            }
            pagingPages.push(<div key="older" onClick={this.older.bind(this)} className={olderClasses}>
                <Icon name="angle double right"/></div>)


            footer = <div className="pagination">{pagingPages}</div>
        }

        let posts;
        if (this.state.posts) {
            posts = (
                this.state.posts.map(post => {
                    let title = cheerio.load(post.title).text();
                    let image;
                    if (post.thumbnail_images && post.thumbnail_images.thumbnail) {
                        let imageUrl = post.thumbnail_images.thumbnail.url;
                        if (imageUrl) {
                            imageUrl = imageUrl.replace("http://simning.skuruik.se", "")
                        }
                        image = <div className="postImage"><img src={imageUrl}/></div>
                        // image = <Item.Image size="small" src={post.thumbnail_images.thumbnail.url}/>;
                    }
                    let excerpt = cheerio.load(post.excerpt).text();
                    let maxLength = 200;
                    if (!this.props.raw) {
                        if (excerpt.length > maxLength) {
                            excerpt = excerpt.substring(0, excerpt.indexOf(" ", maxLength)) + "...";
                        }
                    } else {
                        excerpt = <div dangerouslySetInnerHTML={{__html: post.content}}/>;
                    }

                    let date = new Moment(post.date);
                    let meta;
                    if (this.props.showMeta === true) {
                        meta = (
                            <div className="meta">
                                {post.author.name + " - " + date.format("YYYY-MM-DD")}
                            </div>
                        );
                    }
                    let body = (
                        <div>
                            <h4>{title}</h4>
                            {image}
                            {meta}
                            <div>{excerpt}</div>
                        </div>
                    );
                    if (this.props.noLink !== true) {
                        body = (
                            <Link to={"/post/" + post.slug}>
                                {body}
                            </Link>
                        )
                    }
                    return (
                        <div className="postexcerpt" key={post.id}>
                            <div className="postexcerptBody">
                                {body}
                            </div>
                        </div>
                    );
                })
            );
        } else {
            posts = (
                <Dimmer active inverted>
                    <Loader inverted>Hämtar {this.props.title.toLowerCase()}</Loader>
                </Dimmer>
            );
        }

        return (
            <div className={"posts " + this.props.postType}>
                <h2>{this.props.title}</h2>
                <div className={"postsWrapper " + (this.props.style ? this.props.style : "")}>
                    {posts}
                </div>
                <div>{footer}</div>
            </div>
        );
    }
}

PostListing.propTypes = {
    postType: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    showMeta: PropTypes.bool,
    style: PropTypes.string,
    raw: PropTypes.bool,
    noLink: PropTypes.bool
};

export default PostListing;