import React from "react";
import PropTypes from "prop-types";
import {Icon} from "semantic-ui-react";

export default class SubMenuArrow extends React.Component {
    render() {
        let open = this.props.open === true;
        let style = {
            transition: "",
            transform: "rotate(" + (open ? "180deg" : "0deg") + ")",
            transformOrigin: "bottom middle"
        };
        return (
            <div {...this.props} className="menuArrow">
                <Icon className={open ? "open" : "closed"} name={"caret down"}/>
            </div>
        )
    }
}

SubMenuArrow.propTypes = {
    open: PropTypes.bool,
};