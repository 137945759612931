import Moment from "moment";

export const displayTime = (time) => {
    let moment = new Moment(time);
    return moment.format("mm:ss.SS");
};

export const displayEvent = (event) => {
    if (window.innerWidth <= 414) {
        let eventParts = event.split(" ");
        let distance = eventParts[0].replace("m", "");
        return distance + " " + eventParts[1].toLowerCase().substring(0, 2);
    } else {
        return event;
    }
};
