import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
    <App />,
  document.getElementById('root')
);

window.genderLookup = function (gender, short) {
    let result;
    switch (gender) {
        case "female":
            result = "Dam";
            break;
        case "male":
            result = "Herr";
            break;
        case "mix":
            result = "Mix";
            break;
        default:
            result = gender;
    }
    if (window.innerWidth <= 1024 && short) {
        result = result.substring(0, 1);
    }
    return result;
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
