import React from "react";
import {Button} from "semantic-ui-react";

export default class CookieBanner extends React.Component {

    acceptCookie() {
        let cookieDate = new Date;
        cookieDate.setFullYear(cookieDate.getFullYear() + 10);
        document.cookie = "cookies-accepted=true; expires=" + cookieDate.toUTCString() + ";path=/";
        this.setState({changed: true});
    }

    render() {
        let styles = {
            wrapper: {
                position: "fixed",
                minHeight: "56px",
                backgroundColor: "#3c3c3c",
                width: "100%",
                zIndex: 10000,
                color: "#fff",
                fontWeight: "bold",
                left: 0,
            },
            span: {
                padding: "10px",
            },
            inner: {
                maxWidth: "1200px",
                width: "100%",
                marginLeft: "auto",
                marginRight: "auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                height: "100%",
                minHeight: "56px",
                padding: "0 20px 0 20px",
            }
        };

        if (document.cookie && document.cookie.indexOf("cookies-accepted=true") >= 0) {
            return null;
        }
        return (
            <div style={styles.wrapper}>
                <div style={styles.inner}>
                    <div style={styles.span}>
                        För att Skurus hemsida ska fungera krävs att så kallade cookies sätts i din webbläsare. <a
                        href="https://sv.wikipedia.org/wiki/Webbkaka" target="_blank">Läs mer om cookies</a>
                    </div>
                    <Button color="green" onClick={this.acceptCookie.bind(this)}>OK</Button>
                </div>
            </div>
        )
    }
}