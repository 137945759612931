import React from "react";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import request from "superagent";
import cheerio from "cheerio";
import "./viewposts.scss";
import {Tab} from "semantic-ui-react";
import {MetaTags} from "react-meta-tags";
import {withRouter} from "../../util/withRouter";

class ViewPost extends React.Component {
    constructor() {
        super();
        this.state = ({url: null, post: null, wpPost: null, startlist: null, results: null});
    }

    componentWillReceiveProps(nextProps) {
        // this.setState({url: nextProps.location.pathname.substring("/page".length)});
    }


    componentWillMount() {
        let slug = this.props.router.location.pathname.substring("/post".length + 1);
        this.fetchContent(slug, (post) => {
            this.setState({post});

            if (post.custom_fields && post.custom_fields.livetiming_id) {
                this.fetchLiveTimingResults(post.custom_fields.livetiming_id, result => {
                    this.setState({results: result});
                });
                this.fetchLiveTimingstartlist(post.custom_fields.livetiming_id, result => {
                    this.setState({startlist: result});
                });
                this.setState({livetiming: true});
            }

        })
    }

    fetchLiveTimingResults(id, callback) {
        let url = "/livetiming.se/results.php?all=1&lang=1&cid=" + id;
        fetch(url)
            // .then((res => res.text()))
            .then(res => res.arrayBuffer())
            .then(buffer => {
                const decoder = new TextDecoder('utf-8');
                const text = decoder.decode(buffer);
                return text;
            })
            .then(text => {
                // let enc = new TextDecoder("utf-8");
                text = text.replaceAll("Calibri", "Oswald, sans-serif")
                // let text = enc.decode(res.body);
                // let text = res.body;
                let $ = cheerio.load(text);
                $("tr td:contains('Skuru')").each((i, elem) => {
                    $(elem).parent().attr("class", "our-athlete");
                });

                $("table").each((i, elem) => {
                    let re =/width:\d*?px/g;
                    $(elem).attr("style", "width:100%;padding-top: 1em;"); //elem.attribs.style.replaceAll(re, "width=100%"))
                })
                $("td").each((i, elem) => {
                    let re =/width:\d*?px/g;
                    $(elem).attr("style", "line-height: normal;font-size:0.9em;"); //elem.attribs.style.replaceAll(re, "width=100%"))
                })
                $("tr").each((i, elem) => {
                    let re =/width:\d*?px/g;
                    $(elem).attr("style", null); //elem.attribs.style.replaceAll(re, "width=100%"))
                })
                $("div").each((i, elem) => {
                    let re =/width:\d*?px/g;
                    $(elem).attr("style", null); //elem.attribs.style.replaceAll(re, "width=100%"))
                })


                $(".cs3FDCF3F1").each((i, elem) => {
                    $(elem).attr("style", "border-bottom: 1px solid grey;"); //elem.attribs.style.replaceAll(re, "width=100%"))
                })
                $(".csCAB76A03").each((i, elem) => {
                    $(elem).attr("style", "font-size: 1em;"); //elem.attribs.style.replaceAll(re, "width=100%"))
                })
                $(".csDCA4E998").each((i, elem) => {
                    $(elem).attr("style", "font-size: 1.2em;"); //elem.attribs.style.replaceAll(re, "width=100%"))
                })




                $("tr td:contains('&nbsp;')").each((i, elem) => {
                    $(elem).parent().text("");
                });
                let result = $(".center_content .center > table").parent().html();
                if (result !== null) {
                    callback(result);
                } else {
                    callback("<p><p><i>Resultatet är ännu inte tillgängligt</i></p></p>");
                }
            });
    }

    fetchLiveTimingstartlist(id, callback) {
        let url = "/livetiming.se/program.php?lang=1&all=1&cid=" + id;

        fetch(url)
            // .then((res => res.text()))
            .then(res => res.arrayBuffer())
            .then(buffer => {
                const decoder = new TextDecoder('utf-8');
                const text = decoder.decode(buffer);
                return text;
            })
            .then(text => {
                text = text.replaceAll("Calibri", "Oswald, sans-serif")
                let $ = cheerio.load(text);

                $("tr td:contains('Skuru')").each((i, elem) => {
                    $(elem).parent().attr("class", "our-athlete");
                    $(elem).parent().children().each((i, e) => {
                        $(e).attr("style", "border-bottom: 1px solid grey;");
                    })
                });

                $("table").each((i, elem) => {
                    let re =/width:\d*?px/g;
                    $(elem).attr("style", "width:100%;padding-top: 1em;"); //elem.attribs.style.replaceAll(re, "width=100%"))
                })
                $("tr").each((i, elem) => {
                    let re =/width:\d*?px/g;
                    $(elem).attr("style", null); //elem.attribs.style.replaceAll(re, "width=100%"))
                })
                $("div").each((i, elem) => {
                    let re =/width:\d*?px/g;
                    $(elem).attr("style", null); //elem.attribs.style.replaceAll(re, "width=100%"))
                })
                $("td").each((i, elem) => {
                    let re =/width:\d*?px/g;
                    $(elem).attr("style", "line-height: normal;font-size:0.9em;background-color: transparent"); //elem.attribs.style.replaceAll(re, "width=100%"))
                })
                $(".csCAB76A03").each((i, elem) => {
                    $(elem).attr("style", "font-size: 1em;"); //elem.attribs.style.replaceAll(re, "width=100%"))
                })
                $(".csDCA4E998").each((i, elem) => {
                    $(elem).attr("style", "font-size: 1.2em;"); //elem.attribs.style.replaceAll(re, "width=100%"))
                })



                callback($(".center_content .center > table").parent().html());
            });
    }

    fetchContent(slug, callback) {
        request.get("//" + window.location.host + "/wp/?json=get_post&slug=" + slug)
            .end((err, res) => {
                callback(res.body.post)
            })
    }

    render() {
        if (!this.state.post) {
            return null;
        }
        let post = this.state.post;

        let image;
        let imageUrl;
        if (post.thumbnail_images && post.thumbnail_images.medium) {
            image = <div className="postImage"><img src={post.thumbnail_images.medium.url}/></div>
            imageUrl = post.thumbnail_images.medium.url;
        }

        let excerpt = cheerio.load(post.excerpt).text();
        let title = cheerio.load(post.title).text();
        let maxLength = 300;
        if (excerpt.length > maxLength) {
            excerpt = excerpt.substring(0, excerpt.indexOf(" ", maxLength)) + "...";
        }

        let meta = (
            <MetaTags>
                <title>{title}</title>
                <meta property="og:url"
                      content={"https://www.skurusim.se/post/" + post.slug}/>
                <meta property="og:type" content="article"/>
                <meta property="og:title" content={title}/>
                <meta property="og:description"
                      content={excerpt}/>
                <meta property="og:image"
                      content={imageUrl ? imageUrl : "https://www.skurusim.se/images/sharing_image.png"}/>
            </MetaTags>
        );


        // if custom_fields livetiming_id

        let submenu;
        if (this.state.post && this.state.post.children) {
            submenu = (
                <ul className="mobileShortcutNav">
                    {this.state.post.children.map(child => {
                        return <li key={child.key}><Link to={child.value}>{child.text}</Link></li>
                    })}
                </ul>
            );
        }

        let startlist, results;

        let panes = [];
        // if (this.state.results) {
        if (this.state.livetiming) {
            panes.push({
                menuItem: "Resultat",
                key: this.state.results ? "R" : "noR",
                render: () => <Tab.Pane key={this.state.results ? "R" : "noR"} loading={this.state.results === null}>
                    <div key={this.state.results ? "R" : "noR"} className="tabContent livetimingContent resultlist"
                         dangerouslySetInnerHTML={{__html: this.state.results}}/>
                </Tab.Pane>
            });
            // }
            panes.push({
                menuItem: "Startlista",
                key: this.state.results ? "S" : "noS",
                render: () => <Tab.Pane key={this.state.results ? "S" : "noS"} loading={this.state.startlist === null}>
                    <div key={this.state.results ? "S" : "noS"} className="tabContent livetimingContent startlist"
                         dangerouslySetInnerHTML={{__html: this.state.startlist}}/>
                </Tab.Pane>
            });
        }
        let tab;
        if (panes.length > 0) {
            tab = <Tab key={(this.state.results ? "R" : "noR") + (this.state.results ? "S" : "noS")} panes={panes}/>;
        }

        return (
            <div className="viewpost">
                {meta}
                {/*<div><a href="#" onClick={this.goback.bind(this)}>&lt; Tillbaka</a></div>*/}
                <h1 dangerouslySetInnerHTML={{__html: post.title}}></h1>
                {image}
                <div dangerouslySetInnerHTML={{
                    __html: post.content.replaceAll("https?://sportadmin.se/form/form.asp", "/forms").replaceAll("http://simning.skuruik.se", "")
                }}></div>
                {tab}
                {submenu}
            </div>
        );
    }

    goback() {
        this.props.history.go(-1);
        // this.props.history.back();
    }
}

ViewPost.propTypes = {};

export default withRouter(ViewPost);