import React, {useState} from "react";
import "../styles/main.scss";
import {Icon} from "semantic-ui-react";
import MultiLevelDropdown from "./responsivemenu";
import PropTypes from "prop-types";
import CookieBanner from "./CookieBanner";
import {withRouter} from "../util/withRouter";
import {useNavigate} from "react-router-dom";
import logo from "../images/skuru_jubileumslogga_100.png";

/**
 * The Layout class is the "wrapper" of all content. As defined in App.js the router is a child
 * of layout meaning that changes to routes causes change to the children of the Layout.
 */

function Layout(props) {
    let navigate = useNavigate();
    const [menuOpen, setMenuOpen] = useState(false);
    const [menu, ] = useState(props.menu);
    const [activeItem, setActiveItem] = useState(null);
    // const [loginTime, setLoginTime] = useState(null);

    const itemClick = (item) => {
        if (item.external === true) {
            document.location = item.value;
        } else {
            navigate(item.value);
            setActiveItem(item);
            setMenuOpen(false);
        }
    }

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    }

    let dropdown = (
        <div className="menuOpenerWrapper">
            <Icon className="menuOpenerButton" onClick={() => toggleMenu()} name="bars"/>
        </div>
    );

    return (
        <div>
            <CookieBanner/>
            <div className="pageHeader">
                <div className="title">
                    <img alt="Skuru IK Simning" onClick={() => itemClick({value: "/"})} className="logo"
                         src={logo}/>
                    <span onClick={() => itemClick({value: "/"})} className="header">Skuru Sim</span>
                    <div style={{display: "flex", alignItems: "center"}}>
                        {dropdown}
                    </div>
                </div>
                <div className="menu-wrapper">

                </div>
            </div>
            <div className="contentWrapper">
                <MultiLevelDropdown {...props}
                                    icon="bars" text="" labeled={false} className="inverted" fluid
                                    open={menuOpen}
                                    onItemClick={(item) => itemClick(item)}
                                    value={activeItem}
                                    closeOnValueChange={window.innerWidth <= 768}
                                    options={menu}/>
                <div className="contentArea">
                    {props.children}
                </div>
            </div>
        </div>
    );
}

Layout.propTypes = {
    menu: PropTypes.array.isRequired,
    pageLookup: PropTypes.object.isRequired,
    loginStateChange: PropTypes.func
};

export default withRouter(Layout);