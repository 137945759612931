import React from "react";
import "./Startpage.scss";
import PostListing from "../components/postlisting";
import MetaTags from 'react-meta-tags';
import {withRouter} from "../util/withRouter";

class Startpage extends React.Component {
    constructor() {
        super();
        this.state = ({
            klubbinfo: [],
            klubbinfoPage: 1,
            tavlingsinfo: [],
            tavlingsinfoPage: 1,
            sponsorInfo: []
        });
    }

    render() {
        return (
            <div className="startpage">
                <MetaTags>
                    <title>Skuru IK Simning</title>
                    <meta property="og:url"
                          content="https://www.skurusim.se/"/>
                    <meta property="og:type" content="website"/>
                    <meta property="og:title" content="Skuru IK Simning"/>
                    <meta property="og:description"
                          content="Din simklubb i Nacka - lära sig simma, träna simning, tävla i simning"/>
                    <meta property="og:image"
                          content="https://www.skurusim.se/images/sharing_image.png"/>
                </MetaTags>
                <div className="newsArea">
                    <PostListing showMeta={true} postType="klubbinfo" title="Nyheter"/>
                </div>
                <div className="rightArea">
                    {/*<div className="calendar">*/}
                    {/*    <CompetitionSmall title="Kommande aktiviteter"/>*/}
                    {/*    <Link to="/competitions">Se alla</Link>*/}
                    {/*</div>*/}
                    <div>
                        <PostListing postType="tavlingsinfo" title="Tävlingsinformation"/>
                    </div>
                </div>
                <div>
                    <PostListing noLink={true} style="horizontal" raw={true} postType="sponsor" title="Sponsorer"/>
                </div>
            </div>
        );
    }

}

export default withRouter(Startpage);