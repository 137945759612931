import React from "react";
import {Link} from "react-router-dom";
import {ageRecordAges, ageRecords, clubRecords, eventRecords, finaRecords} from "../../api/records";
import Moment from "moment";
import {Button, Dropdown} from "semantic-ui-react";
import PropTypes from "prop-types";
import "./records.scss"
import {displayTime} from "../../util/utilfuncs";
import {withRouter} from "../../util/withRouter";

class EventRecords extends React.Component {
    constructor() {
        super();
        this.state = ({records: [], event: null});
    }

    componentWillMount() {
        this.loadEventRecords(Object.assign({}, this.props.router.params));
    }

    loadEventRecords(event) {
        if (event.age === "null") {
            event.age = null;
        } else if (event.age.match(/\d+-\d+/)) {
            let ageParts = event.age.split(/-/);
            if (ageParts[0] === ageParts[1]) {
                event.age = ageParts[0];
            }
        }
        eventRecords(event.eventId, event.gender, event.pool, event.age, records => {
            this.setState({records, event});
        });
    }

    render() {
        let backLink = "/records";
        let pageTitle = "Grenrekord";
        if (this.state.event && this.state.event.age) {
            pageTitle += " för åldern " + this.state.event.age + " år";
            backLink += "/" + this.state.event.age;
        }
        let recordTitle = ""; //this.props.event.name + " " + window.genderLookup(this.props.event.gender) + " (" + this.props.event.pool + "m)";
        if (this.state.records && this.state.records.length > 0) {
            let r = this.state.records[0];
            recordTitle = r.eventName + " " + window.genderLookup(r.gender) + " (" + r.track + "m)";
        }
        return (
            <div className="records">
                <h1>{pageTitle}</h1>
                <Link to={backLink}>&lt;&lt; Tillbaka</Link>
                <p>Bästa grentider visar endast simmares bästa tid på sträckan.</p>
                <table className="recordtable">
                    <tbody>
                    <tr key="header">
                        <td className="recordheader stroke" colSpan={6}>Bästa grentider för {recordTitle}</td>
                    </tr>
                    <tr>
                        <th className="rank recordheader">#</th>
                        <th className="name recordheader">Namn</th>
                        <th className="date recordheader">Datum</th>
                        <th className="age recordheader">Ålder</th>
                        <th className="competition recordheader">Tävling</th>
                        <th className="time recordheader">Tid</th>
                    </tr>
                    {this.state.records.map((record, i) => {
                        return (
                            <tr key={record.id}>
                                <td className="rank">{i + 1}</td>
                                <td className="name">
                                    <div><a
                                        href={"http://tempusopen.se/index.php?r=swimmer/view&id=" + record.swimmerId}>{record.firstname + " " + record.lastname}</a>
                                    </div>
                                </td>
                                <td className="date">{record.date}</td>
                                <td className="age">{record.age}</td>
                                <td className="competition">{record.competition}</td>
                                <td className="time">{displayTime(record.time)}</td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>

            </div>
        )
    }
}

export default withRouter(EventRecords)