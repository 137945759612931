import request from "superagent";

export const clubRecords = (filters, callback) => {
    request
        .post("/api/records/query/club")
        .send(filters || [])
        .set("Accept", "application/json")
        .end((err, res) => {
            if (callback) {
                callback(res.body);
            }
        });
};
export const finaRecords = (callback) => {
    request
        .get("/api/records/query/fina")
        .set("Accept", "application/json")
        .end((err, res) => {
            if (callback) {
                callback(res.body);
            }
        });
};


export const ageRecordAges = (callback) => {
    request
        .get("/api/records/query/ages")
        .set("Accept", "application/json")
        .end((err, res) => {
            if (callback) {

                let ages = {};
                res.body.forEach(a => {
                    if (a < 25) {
                        ages[a] = {max: a, min: a};
                    } else {
                        let byfive = Math.floor(a / 5) * 5;
                        let ageGroup = byfive + "-" + (byfive + 4);
                        ages[ageGroup] = {min: byfive, max: byfive + 4};
                    }
                });

                callback(ages);
            }
        });
};

export const ageRecords = (from, to, callback) => {
    request
        .get("/api/records/query/age/" + from + "/" + to)
        .set("Accept", "application/json")
        .end((err, res) => {
            if (callback) {
                callback(res.body);
            }
        });
};

export const eventRecords = (eventId, gender, pool, age, callback) => {
    request
        .get("/api/records/query/event/" + eventId + "/" + gender + "/" + pool + "/" + age)
        .set("Accept", "application/json")
        .end((err, res) => {
            if (callback) {
                callback(res.body);
            }
        });
};

export const findSwimmer = (query, competitionType, callback) => {
    request
        .get("/api/records/findswimmer/"+competitionType+"/"+encodeURI(query))
        .set("Accept", "application/json")
        .end((err, res) => {
            if (callback) {
                callback(res.body);
            }
        });
};


